<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>
 
    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
        <!--      搜素与添加区域-->
      <el-row :gutter="24">
        <el-col :span="13">
             <Title :title="title[0]"></Title>
        </el-col>
        <el-col :span="11" class="right_group">
                <el-date-picker v-model="timeValue"  type="daterange"  align="right" unlink-panels  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"></el-date-picker>
             <!-- clearable清空文本框，@clear="getUserList"恢复原数据展示-->
               <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable ></el-input>
               <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>

      <!-- 列表区域  border加边框 stripe隔行变色 -->
      <el-table :data="accountdetails.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
        <el-table-column type="index" label="序号" width="50px"></el-table-column>
        <el-table-column label="提交时间" prop="ctime"></el-table-column>
        <el-table-column label="备注信息" prop="remarks"></el-table-column>
        <el-table-column label="流水单号" prop="order"></el-table-column>
        <el-table-column label="商家ID" prop="sellerid"></el-table-column>
        <el-table-column label="交易本金" prop="money"></el-table-column>
        <el-table-column label="余额" prop="balance"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="accountdetails.page_arr"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="accountdetails.num"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: ["账户明细"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "账户明细",
          path: "detailed",
          isClick: true
        }
      ],
      //获取账户明细列表的参数对象
      queryInfo: {
        //搜索数据
        query: "",
        // 当前的页数
        pagenum: 1,
        //当前每一页显示多少条数据
        pagesize: 10
      },
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      timeValue: []

    };
  },
  computed:{
       ...mapState({
           accountdetails:state => state.accountDetails.accountdetails
       })
  },
  methods:{
        //获取账户明细数据
        getAccountDetailsData(){  //解决clear存在的问题
             if(this.timeValue != null){
                this.$store.dispatch('getAccountDetailsData',{condition:this.queryInfo.query,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum,stime:this.timeValue[0],etime:this.timeValue[1]})
             }else{
                this.timeValue = []
                this.$store.dispatch('getAccountDetailsData',{condition:this.queryInfo.query,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum,stime:this.timeValue[0],etime:this.timeValue[1]})
             }
             
        },
        //监听pagesize改变的事件
        handleSizeChange(newSize) {
          this.queryInfo.pagesize = newSize;
          this.queryInfo.pagenum = 1;
          this.getAccountDetailsData();
        },
        
        //监听页码值改变的事件
        handleCurrentChange(newPage) {
          this.queryInfo.pagenum = newPage;
          this.getAccountDetailsData();
        },
        //搜索
        search(){
            this.queryInfo.pagenum = 1;
            this.getAccountDetailsData()
        },
  },
  created(){
        this.getAccountDetailsData()
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin: 20px 20px 0 20px;
}
.el-table {
    margin-top: 20px;
}
.el-pagination {
  margin-top: 20px;
}
.el-date-picker{
  margin-right: 0 !important;
}
.right_group{
   display: flex;
}
.el-input{
   margin:0 10px 0;
   width: 300px;
}
</style>